import React, { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { APP_CONFIG } from '@constants';
import withRouter from './withRouter';
import BasicLayout from  './rootLayout';
import PrivateRoute from './privateRoute';
import PrivateRouteForActivation from './privateRouteForActivation';
import NotFound from './components/404NotFound/index';
import { flowType } from '@utility/userInfo';
import { getObjectValue } from '@utility/utills';


const Orders = withRouter(lazy(() => import('@containers/MenuOrder')))
const UpSell = withRouter(lazy(() => import('@containers/KioskUpsell')))
const StoreChecks = withRouter(lazy(() => import('@containers/StoreChecks/indexCopy')))
const Activation = withRouter(lazy(() => import('@containers/Activation')));
const OrderItemDetail = withRouter(lazy(() => import('@containers/OrderItemDetail')));
const Receipt = withRouter(lazy(() => import('@containers/ViewReceipt')))
const GameCard = withRouter(lazy(() => import('@containers/GameCard')))
const GiftCard = withRouter(lazy(() => import('@containers/GiftCard')))
const CallCenterError = withRouter(lazy(() => import('@containers/CallCenterError')))
const Stage = withRouter(lazy(() => import('@containers/Stage')))
const StagePayment = withRouter(lazy(() => import('@containers/StagePayment/indexCopy')))
const CheckIn = withRouter(lazy(() => import('@containers/CheckIn')))
const FireboardChecks = withRouter(lazy(() => import('@containers/FireboardChecks')))
const TableTop = withRouter(lazy(() => import('@containers/TableTop')))
const Kiosk = withRouter(lazy(() => import('@containers/Kiosk')))
const Callcenter = withRouter(lazy(() => import('@containers/CallCenter')))
const GuestInformationRevamp = withRouter(lazy(() => import('@containers/CallCenter/GuestInformationRevamp')))
const PaymentTerminal = withRouter(lazy(() => import('@containers/PaymentTerminal')))
const Cubby = withRouter(lazy(() => import('@containers/Cubby')))
const OrderUp = withRouter(lazy(() => import('@containers/OrderUp')))
const KitchenDisplaySystem = withRouter(lazy(() => import('@containers/KitchenDisplaySystem')))
const QrScanner = withRouter(lazy(() => import('@containers/QrScanner')))
const CashOut = withRouter(lazy(() => import('@containers/CounterCash')))
const ErrorPage = withRouter(lazy(() => import('@components/common/ErrorPage')))
const CashIn = withRouter(lazy(() => import('@containers/CounterCash/counterCash')))
const ServerCashOutReport = withRouter(lazy(() => import('@components/serverCashoutReport/indexCopy')))
const CounterCash =  withRouter(lazy(() => import('@containers/CounterCash/counterCash')))
const Confirmationpage =  withRouter(lazy(() => import('@containers/CounterCash/cashOutConfirmation')))
const KioskLandingPage =  withRouter(lazy(() => import('@containers/Kiosk/kioskLandingPage')))
const CheckName =  withRouter(lazy(() => import('@containers/CheckName')))
const Cart =  withRouter(lazy(() => import('@containers/KioskCart')))
const Order =  withRouter(lazy(() => import('@containers/MenuOrder')))
const Pay =  withRouter(lazy(() => import('@containers/KioskPaymentScreens')))
const KioskOrder =  withRouter(lazy(() => import('@containers/Kiosk/kioskLandingPage')))
const KioskOrderItemDetails =  withRouter(lazy(() => import('@containers/OrderItemDetail/kioskOrderItemDetails')))
const KioskUpsell =  withRouter(lazy(() => import('@containers/KioskUpsell')))
const LoyaltyBalance =  withRouter(lazy(() => import('@containers/ViewReceipt/loyaltyBalanceNewUi')))
let getTheme = getObjectValue(() => JSON.parse(window.sessionStorage.getItem('auth')).serverAppTheme);
const Fireboard = withRouter(lazy(() => import('@containers/Fireboard')))

const routes = [
  {
    path: '/',
    element: <BasicLayout/>,
    children: [
      { path: `${APP_CONFIG.BASE_URL}/`, element: <Activation /> },
      { path: `${APP_CONFIG.BASE_URL}/callcenter/landing/tsdglobal`, element: <Activation /> },
      { path: `${APP_CONFIG.BASE_URL}/activate`, element: <Activation /> },
      { path: `${APP_CONFIG.BASE_URL}/activationCode/:deviceExternalId`, element: <Activation /> },
      { path: `${APP_CONFIG.BASE_URL}/qrScan`, element: <QrScanner /> },
      { path: `${APP_CONFIG.BASE_URL}/error`, element: <ErrorPage /> },
      {
        path: '',
        element: <PrivateRoute/>,
        children: [
          { path: `${APP_CONFIG.BASE_URL}/orders`, element: <Orders /> },
          { path: `${APP_CONFIG.BASE_URL}/upsell`, element: <UpSell /> },
          { path: `${APP_CONFIG.BASE_URL}/serverApp/checks`, element: <StoreChecks /> },
          { path: `${APP_CONFIG.BASE_URL}/fireboard/checks`, element: <FireboardChecks /> },
          { path: `${APP_CONFIG.BASE_URL}/tableTop/checks`, element: <TableTop /> },
          { path: `${APP_CONFIG.BASE_URL}/stage/:checkId`, element: <Stage /> },
          { path: `${APP_CONFIG.BASE_URL}/order-item/:menuTypeId/:menuItemId/:internalId/:type`, element: <OrderItemDetail /> },
          { path: `${APP_CONFIG.BASE_URL}/order-item/:menuTypeId/:menuItemId`, element: <OrderItemDetail /> },
          { path: `${APP_CONFIG.BASE_URL}/order-child/:menuTypeId/:menuItemId`, element: <OrderItemDetail /> },
          { path: `${APP_CONFIG.BASE_URL}/stagepayment/:checkId`, element: <StagePayment /> },
          { path: `${APP_CONFIG.BASE_URL}/receipt/:checkId`, element: <Receipt /> },
          { path: `${APP_CONFIG.BASE_URL}/gamecard/:checkId`, element: <GameCard /> },
          { path: `${APP_CONFIG.BASE_URL}/giftcard/:checkId`, element: <GiftCard /> },
          { path: `${APP_CONFIG.BASE_URL}/callCenterError`, element: <CallCenterError /> },
          {
            path: `${APP_CONFIG.BASE_URL}/kiosk`,
            element: <Kiosk />,
            children: [
              { path: `${APP_CONFIG.BASE_URL}/kiosk/landing`, element: <KioskLandingPage /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/checks`, element: <CheckName /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/cart`, element: <Cart /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/upsell`, element: <KioskUpsell /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/orders`, element: <Order /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/pay`, element: <Pay /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/kioskOrder`, element: <KioskOrder /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/order-item/:menuTypeId/:menuItemId/:internalId/:type`, element: <KioskOrderItemDetails /> },
              { path: `${APP_CONFIG.BASE_URL}/kiosk/order-item/:menuTypeId/:menuItemId`, element: <KioskOrderItemDetails /> }
            ]
          },
          {
            path: `${APP_CONFIG.BASE_URL}/callcenter`,
            element: <Callcenter />,
            children: [
              {
                path: `${APP_CONFIG.BASE_URL}/callcenter/guestinformation`,
                element: <GuestInformationRevamp />
              }
            ]
          },
          { 
            path: `${APP_CONFIG.BASE_URL}/cashOut`, 
            element: <CashOut />,
            children: [
              {path: `${APP_CONFIG.BASE_URL}/cashOut/bills`, element: <CounterCash />},
              {path: `${APP_CONFIG.BASE_URL}/cashOut/confirmation`, element: <Confirmationpage />}
            ]
          },
          { path: `${APP_CONFIG.BASE_URL}/paymentTerminal`, element: <PaymentTerminal /> },
          { path: `${APP_CONFIG.BASE_URL}/cashIn`, element: <CashIn /> },
          { path: `${APP_CONFIG.BASE_URL}/report/server/cashOut`, element: <ServerCashOutReport /> },
          { path: `${APP_CONFIG.BASE_URL}/loyalty-balance`, element: <LoyaltyBalance /> },
        ]
      },
      {
        path: '',
        element: <PrivateRouteForActivation />,
        children: [
          { path: `${APP_CONFIG.BASE_URL}/checkIn`, element: <CheckIn /> },
          { path: `${APP_CONFIG.BASE_URL}/cubby/checks`, element: <Cubby /> },
          { path: `${APP_CONFIG.BASE_URL}/orderUp/checks`, element: <OrderUp /> },
          { path: `${APP_CONFIG.BASE_URL}/kds/checks`, element: <KitchenDisplaySystem /> },
          { path: `${APP_CONFIG.BASE_URL}/fireboard`, element: <Fireboard /> }
        ]
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]
const router = createBrowserRouter(routes)

export default (
  <div className='page' style={{ background: flowType() === 'fireboard' ? '#000' : getTheme !== '2' ? '#000' : '#f5f7fb' }}>
    <RouterProvider router={router}/>
  </div>
)