
export const displayTime = (closeDate, time) => {
    let displayValue;
    const sentTime = new Date(time).getTime() / 1000;
    let closedTime;
    if (closeDate !== null) {
      closedTime = new Date(closeDate).getTime() / 1000;
    } else {
      // currentTime = Date.now() / 1000;
      closedTime = new Date(time).getTime() / 1000;
    }
    const timeElapsed = parseInt((closedTime - sentTime) / 60);
    if (timeElapsed < 120) {
      displayValue = `${timeElapsed}m`;
    } else {
      displayValue = '2h+';
    }
    return displayValue;
  };

  export const getTimeIn12HourClock = time => {
    if (!time) return;
    const splitValues = String(time).split(':');
    const hours = +splitValues[0];
    const session = hours >= 12 ? 'PM' : 'AM';
    let hrs = hours > 12 ? hours - 12 : hours;
    hrs = hrs === 0 ? 12 : hrs; // adding condition to handle 12 AM
    hrs = hrs < 10 ? `0${hrs}` : hrs;
    const mins = +splitValues[1] < 10 ? `0${+splitValues[1]}` : +splitValues[1];
    return `${hrs}:${mins} ${session}`;
  };

  export const getDeliverTo = (check) => {
    const { sourceType } = check || {};
    const order = check.orders && check.orders.length > 0 && check.orders[0];
    const vehicleInfo = order?.vehicleInfo || null;
    const checkInNote = check?.checkInNote || null;
    const checkName = check?.name || null;
    switch (sourceType) {
      case 0: // POS, DEFAULT
        let posName;
        if (checkInNote)
          posName = posName ? `${posName}\n${checkInNote}` : checkInNote;
        if (vehicleInfo)
          posName = posName ? `${posName}\n${vehicleInfo}` : vehicleInfo;
        if (checkName && checkName !== checkInNote)
          posName = posName ? `${posName}\n${checkName}` : checkName;

        if (check.tab) {
          return posName ? posName : "- -";
        } else {
          if (check.stationNumber && check.name) return `${check.stationNumber}\n${check.name}`;
          else if (check.stationNumber) return check.stationNumber;
          else if (check.name) return check.name;
          else return "- -";
        }

      case 1: // KIOSK
      case 5: // CURBSIDE
      case 6: // ONLINE
      case 7: // WALKUP
      case 8: // TEXTTOPAY
      case 9: // SCANTOPAY
        let name;
        if (checkInNote)
          name = name ? `${name}\n${checkInNote}` : checkInNote;
        if (vehicleInfo)
          name = name ? `${name}\n${vehicleInfo}` : vehicleInfo;
        if (checkName && checkName !== checkInNote)
          name = name ? `${name}\n${checkName}` : checkName;
        return name || "- -";

      default:
        let defaultName;
        if (checkInNote)
          defaultName = defaultName ? `${defaultName}\n${checkInNote}` : checkInNote;
        if (vehicleInfo)
          defaultName = defaultName ? `${defaultName}\n${vehicleInfo}` : vehicleInfo;
        if (checkName && checkName !== checkInNote)
          defaultName = defaultName ? `${defaultName}\n${checkName}` : checkName;

        if (check.sourceType === 0) {
          return defaultName || check.stationNumber || "- -";
        }
        return defaultName || "- -";
    }
  };

  // export const formattedPhoneNumber = (phoneNumber, iso2) => {
  //   if (phoneNumber && iso2) {
  //     return new AsYouType(iso2).input(phoneNumber);
  //   }
  //   return phoneNumber;
  // }

  export const getPaidStatusIcon = (check, currency = '$') => {
    let paidImg, preAuthImg;
    const { tickets } = check || {};
    if (Array.isArray(tickets) && tickets.length > 0) {
      for (let ticketIdx = 0, ticketLen = tickets.length; ticketIdx < ticketLen; ticketIdx++) {
        const { ticketStatusId, preAuth } = tickets[ticketIdx] || {};
        if (ticketStatusId === 0 && preAuth && check.unpaidAmount !== 0 && check.paidAmount > 0) {
          preAuthImg = currency === '£' ? "partialPaidPreAuthPound" : "partialPaidPreAuthDollar";
          break;
        } else if (ticketStatusId === 0 && preAuth && check.unpaidAmount > 0) {
          preAuthImg = currency === '£' ? "preAuthPound" : "preauth";
          break;
        }
      }
    }
    if (check && check.unpaidAmount === 0 && check.paidAmount > 0) {
      paidImg = "paid";
    } else if (check && check.unpaidAmount !== 0 && check.paidAmount > 0) {
      paidImg = "partialPaid";
    } else {
      paidImg = "unpaid";
    }
    return { paidIcon: paidImg, preAuthIcon: preAuthImg };
  };

  export const getTimeInHrsMins = time => {
    var convertTime = time
    let date;
    if (convertTime)
      date = new Date(time);
    else
      date = new Date(time + 'Z');
    const hours = date.getHours();
    const session = hours >= 12 ? 'PM' : 'AM';
  
    let hrs = hours > 12 ? hours - 12 : hours;
    hrs = hrs === 0 ? 12 : hrs; // adding condition to handle 12 AM
    hrs = hrs < 10 ? `0${hrs}` : hrs;
    const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    return !isNaN(hrs) && !isNaN(mins) ? `${hrs}:${mins} ${session}` : '--';
  };
  
  export const getCheckInTime = item => {
    if (item.checkInDate) {
      return getTimeInHrsMins(item.checkInDate);
    }
    return '- -';
  };
  
  export const getElapsedTime = (check) => {
    const currentTime = new Date().getTime();
    const dueTime = check.pickupDate || check.orderDate || check.openDate;
    var convertTime = dueTime && dueTime.includes('Z');
    let date;
    if (convertTime)
      date = new Date(dueTime);
    else
      date = new Date(dueTime + 'Z');
    const orderTime = new Date(date).getTime();    
    return currentTime - orderTime;
  };

  export const getOrderNameFromOrderId = check => {
    const { orders } = check || {};
    const order = Array.isArray(orders) && orders.length > 0 ? orders[0] : {};

    let retVal = {};
    const { unpaidAmount, tickets } = check;
    switch (order.orderStatusId) {
      case 0: {
        retVal = {
          status: check.sourceType !== 0
            ? 'Pending'
            : (unpaidAmount > 0 && tickets && tickets.length > 0)
              ? 'Pending'
              : 'In Progress',
          color: 'orange',
        };
        break;
      }
      case 1: {
        retVal = {
          status: 'In Progress',
          color: 'orange',
        };
        break;
      }
      case 5: {
        const [order0] = check?.orders || [];
        const { pickUpStatus } = order0 || {};
        retVal = {
          status: pickUpStatus === 5
            ? 'Picked Up'
            : pickUpStatus === 3
              ? 'See Counter'
              : 'Complete',
          color: 'green',
        };
        break;
      }
      case 8: {
        retVal = {
          status: "cancelOrderText",
          color: 'red',
        };
        break;
      }
      case 9:
        retVal = {
          status: "Error",
          color: 'red',
        };
        break;
      case 10:
        retVal = {
          status: "orderAbandoned",
          color: 'red',
        };
        break;
      default:
        retVal = {};
    }
    return retVal;
  };

  export const  getCheckSourceIcon = (sourceType, delivery, isMarketOrder, isCatering, isTab) => {
    if (isMarketOrder) {
      return isCatering && delivery ? cateringDeliveryIcon : isCatering ? cateringIcon : delivery ? marketOrderDelivery : marketOrderOnline;
    }
    switch (sourceType) {
      case 0: // POS, DEFAULT
        return (isTab === null || isTab === undefined) ? "pos" : isTab ? "Tab" : "Table";
      case 1: //KIOSK
        return "kiosk";
      case 5: // CURBSIDE
        return "curbSide";
      case 6: // ONLINE
        return delivery ? "deliveryIcon" : "onlineIcon";
      case 7: // WALKUP
        return "walkup";
      case 8: // TEXTTOPAY
        return "textToPay";
      case 9: // SCANTOPAY
        return "scanToPay";
      case 10: //Call Center Order
        return "callCenter";
       case 11:
       return "SpeedOrder" 
      default:
        return '';
    }
  };
  
  export default {
    displayTime,
    getTimeIn12HourClock,
    getDeliverTo,
    getPaidStatusIcon,
    getTimeInHrsMins,
    getCheckInTime,
    getElapsedTime,
    getOrderNameFromOrderId,
    getCheckSourceIcon
  };
  